//REACT
import React, { useState } from 'react';
import LazyLoad from "react-lazyload"
// import React, { useState } from 'react';


//FIREBASE

//MATERIALS
import {makeStyles} from '@mui/styles';
import Grid from "@material-ui/core/Grid";
import {Paper, Hidden, Box, Container}  from "@mui/material";

//INTERNAL IMPORTS
import CenterGrid from './CenterGrid';
import JPTileInner from './JPTileInner';
import GlobalBanner from './GlobalBanner';

//EXTERNAL IMPORTS
import ReactGA from 'react-ga';
import Slider from "react-slick";
import BalanceText from "react-balance-text";
import Helmet from "react-helmet";

//COMPONENTS
import {Square} from "./global/components/Square";
import ShippingFooter from './ShippingFooter/ShippingFooter';

//HOOKS, UTILS, DATA
import { catSlider, dogSlider, heroSlider, otherSlider, home_slider_settings as slider_settings } from './data/homeSliderData';
import { catProps, dogProps, petProps, otherProps1, otherProps2 } from './data/tileProps';


const rowHeight = 400
//REDMAN-settings
const useStyles = makeStyles((theme) => ({
    fullWidth: {
        display: "block",
        margin: "auto",
        width: "100%",
    },
    appStore: {
        display: "block",
        margin: "0 auto",
        [theme.breakpoints.down('md')]: {
            width: "80%",
        },
    },
    jigpaws: {
        backgroundColor: "#413e40",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
    },
    linebreak1: {
        backgroundColor: '#85a2ad',
        height: "1.5vh",
        filter: "brightness(90%)"
    },
    linebreak2: {
        // marginTop: theme.spacing(4),
        backgroundColor: '#413e40',
        height: "1.5vh",
        // height: "10px",
    },
    see: {
        minHeight: rowHeight,
        // height: "calc(40vh - 67px)",
        backgroundColor: "#99bfc2",
        color: "#fff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        [theme.breakpoints.down('md')]: {
            height: "25vh",
            minHeight: 0,
        },
    },
    download: {
        minHeight: rowHeight,
        // height: "calc(40vh - 67px)",
        backgroundColor: "#85a2ad",
        color: "#fff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        [theme.breakpoints.down('md')]: {
            height: "25vh",
            minHeight: 0,
        },
    },
    transparentDivider: {
        height: "5vh",
        backgroundColor: "#fff",
    },
    galleryLink: {
        paddingTop: 10
    },
    moveToFront: {
        [theme.breakpoints.down('md')]: {
            order: -1,
        },
    }
}));




function Slide(props) {
    const classes = useStyles();

    return (
        <LazyLoad height={200} once>
            <Paper elevation={0} square={true} className={classes.jigpaws}>
                <img className={classes.fullWidth} src={props.item.src} alt="" />
            </Paper>
        </LazyLoad>
    );
}



const onGotoAppStore = (e) => {
    ReactGA.event({
        category: "User",
        action: 'Clicked through to appstore',
        label: "https://apps.apple.com/gb/app/jigpaws/id1541336283"
    })
};



export default function Home() {
    const classes = useStyles();
        // const [showGlobalBanner, setShowGlobalBanner] = useState(true); // Default to true or false depending on your requirement

    return <>
        <Helmet>

            <title>Jigpaws – Personalised Pet Gifts | Free Shipping</title>
            <meta name="description"
                  content="Free shipping on all customisable and personalised pet gifts including jigsaws, cushions, cards, notebooks and photo tiles. Find out more with Jigpaws."/>
            {/*<link rel="canonical" href="https://www.jigpaws.com/"/>*/}
            <meta property="og:url"
                  content="https://www.jigpaws.com/"/>
            <meta property="og:type" content="article"/>
            <meta property="og:title" content="Jigpaws – Personalised Pet Gifts | Free Shipping"/>
            <meta property="og:description"
                  content="Free shipping on all customisable and personalised pet gifts including jigsaws, cushions, cards, notebooks and photo tiles. Find out more with Jigpaws."/>
            <meta property="og:image" content="https://www.jigpaws.com/images/social-logo.png"/>
        </Helmet>

        <Container className={"productsHome bgGray"} disableGutters={true} maxWidth={false}>
            <div className="spacer"/>
            <img src="/svg/logo.svg" alt="Jigpaws logo" className="logoStyles"/>
            <div className="spacer"/>
            <div className="jpDivider bgBlue"/>
        </Container>
        <Container
            className={"productsHome bgRed"}
                        align="center"
                        disableGutters={true}
                        maxWidth={false}
                    >
            <GlobalBanner/>
        </Container>
        <Container maxWidth="xxl" disableGutters={true}>
            <main>

                <Grid container spacing={0}>

                    <Grid item xs={12} md={6} order={{xs: 2, sm: 1}} className={classes.moveToFront}>
                        <Square>
                            <div className="mobileNone">
                                <Slider {...slider_settings}>
                                    {
                                        heroSlider.map((item, i) => <Slide key={i} item={item}/>)
                                    }
                                </Slider>
                            </div>
                            <div className="desktopNone">
                                <img className="jpLine" src="/images/Homepage2023/JP_Home_Hero_1.webp" width="100%"
                                     alt="Jigpaws customised cat puzzle and can"/>
                            </div>
                        </Square>
                    </Grid>
                    <Grid item xs={12} md={6} className="jpTile bgGreen">
                        <Box sx={{display: {xs: 'block', sm: 'none'}}}>
                            <CenterGrid>
                                <Grid>
                                    <JPTileInner {...petProps}/>
                                </Grid>
                            </CenterGrid>
                        </Box>
                        <Box sx={{display: {xs: 'none', sm: 'block'}}}>
                            <Square>
                                <CenterGrid>
                                    <JPTileInner balanceTile={true} {...petProps}/>
                                </CenterGrid>
                            </Square>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={0}>
                    <Grid item xs={12} md={6} className="jpTile bgGreen" order={{xs: 1, sm: 1}}>
                        <Hidden smUp={true}>
                            <CenterGrid>
                                <Grid>
                                    <JPTileInner {...dogProps}/>
                                </Grid>
                            </CenterGrid>
                        </Hidden>
                        <Hidden smDown={true}>
                            <Square>
                                <CenterGrid>
                                    <JPTileInner balanceTile={true}{...dogProps}/>
                                </CenterGrid>
                            </Square>
                        </Hidden>
                    </Grid>
                    <Grid item xs={12} md={6} order={{xs: 2, sm: 2}} className={classes.moveToFront}>
                        <Square>
                            <div className="mobileNone">
                                <Slider {...slider_settings}>
                                    {
                                        dogSlider.map((item, i) => <Slide key={i} item={item}/>)
                                    }
                                </Slider>
                            </div>
                            <div className="desktopNone">
                                <img className="jpLine" src="/images/Homepage2023/JP_Home_Dog_1.webp" width="100%"
                                     alt="A dog jigsaw from Jigpaws"/>
                            </div>
                        </Square>
                    </Grid>
                </Grid>
                <Grid container spacing={0}>
                    <Grid item xs={12} md={6}>
                        <Square>
                            <div className="mobileNone">
                                <Slider {...slider_settings}>
                                    {
                                        catSlider.map((item, i) => <Slide key={i} item={item}/>)
                                    }
                                </Slider>
                            </div>
                            <div className="desktopNone">
                                <img className="jpLine" src="/images/Homepage2023/JP_Home_Cat_4.webp" width="100%"
                                     alt="A cat cushion from Jigpaws"/>
                            </div>
                        </Square>
                    </Grid>
                    <Grid item xs={12} md={6} className="jpTile bgGreen">
                        <Hidden smUp={true}>
                            <CenterGrid>
                                <Grid>
                                    <JPTileInner {...catProps}/>
                                </Grid>
                            </CenterGrid>
                        </Hidden>
                        <Hidden smDown={true}>
                            <Square>
                                <CenterGrid>
                                    <JPTileInner balanceTile={true} {...catProps}/>
                                </CenterGrid>
                            </Square></Hidden>
                    </Grid>
                    <Grid container spacing={0}>
                        <Grid item xs={12} md={6} className="jpTile bgGreen">
                            <Hidden smUp={true}>
                                <CenterGrid>
                                    <Grid>
                                        <JPTileInner {...otherProps1}/>
                                    </Grid>
                                </CenterGrid>
                            </Hidden>
                            <Hidden smDown={true}>
                                <Square>
                                    <CenterGrid>
                                        <JPTileInner balanceTile={true} {...otherProps2}/>
                                    </CenterGrid>
                                </Square></Hidden>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.moveToFront}>
                            <Square>
                                <div className="mobileNone">
                                    <Slider {...slider_settings}>
                                        {
                                            otherSlider.map((item, i) => <Slide key={i} item={item}/>)
                                        }
                                    </Slider>
                                </div>
                                <div className="desktopNone">
                                    <img className="jpLine" src="/images/Homepage2023/JP_Home_Other_1.webp" width="100%"
                                         alt="A horse cushion from Jigpaws"/>
                                </div>
                            </Square>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={6} className={classes.moveToFront}>
                        <Square>

                            <img alt="iPhone with Jigpaws website" className={`${classes.appStore} jpLineMob`}
                                 src="/images/home/jigPawsIphone3_2023.jpg"
                                 style={{"width": "100%"}}
                            />
                        </Square>
                    </Grid>
                    <Grid item xs={12} md={6} className="jpTile bgWhite">
                        <Hidden smUp={true}>
                            <CenterGrid>
                                <Grid>
                                    <Grid item>
                                        <div className="jpTileInner">
                                            <h2><BalanceText>PLAY THE JIGSAW GAME</BalanceText></h2>
                                            <div className="spacerMed"/>
                                            <div className="body">
                                                <p>Download the APP and upload a photo of your cute pet to play the
                                                    Jigpaws jigsaw puzzle on your phone.</p>
                                            </div>
                                            <div className="spacerMed"/>
                                            <a onClick={onGotoAppStore}
                                               href="https://apps.apple.com/gb/app/jigpaws/id1541336283"
                                               target="_blank"
                                               rel="noopener noreferrer"
                                            >
                                                <img
                                                    alt="Apple Store logo"
                                                    className={classes.appStore}
                                                    src="/images/APP-STORE-LOGO.png"
                                                    style={{"width": "200px", "height": "59px"}}
                                                />
                                            </a>
                                        </div>
                                    </Grid>
                                </Grid>
                            </CenterGrid>
                        </Hidden>
                        <Hidden smDown={true}>

                            <Square>
                                <CenterGrid>
                                    <Grid item>
                                        <div className="jpTileInner">
                                            <div className="spacerMed"/>

                                            <h2>PLAY THE JIGSAW GAME</h2>
                                            <div className="spacerMed"/>
                                            <div className="body">
                                                <p>Download the APP and upload a photo of your cute pet to play the
                                                    Jigpaws jigsaw puzzle on your phone.</p>
                                            </div>
                                            <div className="spacerMed"/>
                                            <a onClick={onGotoAppStore}
                                               href="https://apps.apple.com/gb/app/jigpaws/id1541336283"
                                               target="_blank"
                                               rel="noopener noreferrer">
                                                <img alt="Apple Store logo"
                                                     className={classes.appStore}
                                                     src="/images/APP-STORE-LOGO.png"
                                                     style={{"width": "200px"}}/></a>
                                            <div className="spacerMed"/>
                                        </div>
                                    </Grid>
                                </CenterGrid>
                            </Square>
                        </Hidden>

                    </Grid>
                </Grid>
            </main>
        </Container>

        <Container disableGutters={true} maxWidth={false}>
            {/* <div className="jpDivider bgGray"/> */}
            <Grid container>
                <ShippingFooter
                    includeCustomAppleSection={true}
                    international={false}
                />
            </Grid>
        </Container>


    </>;
}
