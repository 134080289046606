import React, { useState } from 'react';
import Button from "@mui/material/Button";
// import './Accordion.css'; // Add appropriate styles here



    const buttonStyles = {
    padding: '10px 30px',
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.05em",
    border: "1px solid "
};

    // Define your date condition here
    const currentDate = new Date();
    const lastDisplayDate = new Date('2024-12-25'); // Example: Stop displaying after December 20, 2024

    // Combine the original showGlobalBanner logic with the date condition
    const showGlobalBanner = currentDate <= lastDisplayDate && false;
    // const showGlobalBanner = true;
    const GlobalBanner = ({ sectionTitle, items }) => {
    const [isActive, setIsActive] = useState(false);
    if (!showGlobalBanner) {
    return null; // If showGlobalBanner is false, don't render the banner
    }

    return (
        <div className="accordion bgRed">
        <div
                className="accordionTop accordionCenter bgRed"
                onClick={() => setIsActive(!isActive)}
            >
                <h2>Pre-Christmas delivery</h2>
                <div className="accordionButton">{isActive ? '-' : '+'}</div>
            </div>
            <div
                className={`accordionContent ${isActive ? 'open' : 'closed'}`}
                style={{
                    maxHeight: isActive ? '1000px' : '0',
                    overflow: 'hidden',
                    transition: 'max-height 0.3s ease',
                }}
            >
                <div className="accordionSection">
                    <p>To ensure Santa’s little helpers have time to create your order please order before the following
                        dates to ensure standard* pre-chrismas delivery.</p>
                    <div className="spacerSmall"/>
                    <h4>Jigsaws</h4>
                    <p><strong>UK:</strong> Order by the 20th of December for pre-Christmas delivery.</p>
                    <p><strong>ROW:</strong> Order by the 13th of December for pre-Christmas delivery.</p>
                    <div className="spacerSmall"/>

                    <h4>All other products</h4>
                    <p><strong>UK:</strong> Order by the 17th of December for pre-Christmas delivery.</p>
                    <p><strong>ROW:</strong>Order by the 13th of December for pre-Christmas delivery.</p>
                    <div className="spacerSmall"/>

                    <p>*Express and next day will ship as normal.</p>
                    <div className="spacerMed"/>

                    <Button style={buttonStyles} size="large" variant="outlined" href="/deliveries-and-returns">Read
                        more about delivery</Button>
                    <div className="spacerMed"/>

                </div>
            </div>
        </div>
    );
};

export default GlobalBanner;